.container-center {
    display: flex;
    justify-content: center;
    align-items:center;
    min-height: 10vh; 
    
    height: 700px;
    
  }
  
  .f1a2b3 {
    font-family: "Lucida Console", "Courier New", monospace;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: #313131;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .5);
    border-radius: 8px;
    height: 600px;
  }
  
  .f1a2b3 h2 {
    color: #cecece;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .f1a2b3 div {
    margin-bottom: 20px;
  }
  
  .f1a2b3 label {
    display: block;
    margin-bottom: 8px;
    color: #cacaca;
    font-size: 16px;
    text-align: left;
  }
  
  .f1a2b3 input,
  .f1a2b3 textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
  }
  
  .f1a2b3 textarea {
    height: 100px;
    resize: vertical;
  }
  
  .f1a2b3 button {
    


    padding: 20px;
  width: 200px;
  background-color: #a900b8;
  transition: background-color 0.5s, box-shadow 0.5s, transform 0.5s;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 60px;
  
  border: none;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  }
  
  .f1a2b3 button:hover {
    background-color: #ef44ff;
    transform: translateY(-3px);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }


  .boton-contenedor {
    display: flex;
    justify-content: center; 
    align-items: center; 
  }

 
.success-message {
  display: flex;
    justify-content: center; 
    align-items: center; 
    margin-top: 20px;
    color: #00ff37; 
    background-color: #00584a; 
    border: 1px solid #31a245; 
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    
  }
  
  
  .error-message {
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin-top: 20px;
    color: #d32f2f; 
    background-color: #fbe9e7; 
    border: 1px solid #d32f2f; 
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  }
  

  .f1a2b3 button.close-message {
    margin-top:0;
    margin-left: 10px;
    cursor: pointer;
    border: none;
    background: orange;
    color:#ffffff; 
    font-weight: bold;
    width: 50px;
  }

  .f1a2b3 button.close-message:hover {
    
    background: rgb(202, 131, 0);
    
  }
  

  .sending-message {
    background-color: #00584a;
    margin-top: 20px;
    color: #00ff37; 
    text-align: center;
    margin-top: 10px;
    border: 1px solid #ff1e00;
    border-radius: 4px;
    padding: 10px;
    font-size: 12px;

  }
  